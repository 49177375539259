import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import SideNav from "../../components/SideNav/SideNav";
import Card from "../../components/Card/Card";
import Layout from "../../components/Layout";
import { connect } from "react-redux";
import { graphql } from 'gatsby';
import { getArticles } from "../../reducers/Articles/actions";
import { urlDescription } from '../../utils/pageDescription'
import "./styles.scss";

const Articles = ({ data, page, articles, getArticles }) => {

  useEffect(() => {
    getArticles({ page, ItemsPerPage: 1000 })
  }, [])

  const nodes = data.allStrapiArticles.edges
  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  let newNodes = []

  if (articles.length > 0) {
    newNodes = articles && nodes.slice().map((newNode, i) => {
      if (newNode.node.id === articles[i].id) {
        return newNode
      } else {
        let properNode = nodes.find(n => n.node.id === articles[i].id)
        return properNode
      }
    });
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="building-library-title">
        <h1 className="h2 font-weight-bold">Полезна информация</h1>
      </div>
      <div className="news-wrapper">
        <div className="news-page-card-container">
          <div className="news-page-title">
            <h2 className="h3 font-weight-bold">Статии</h2>
          </div>
          <Row className="news-page-card-row">
            <Col className='mb-5 sidenav-menu-col' xs={12} md={4} lg={4}>
              <SideNav articles active="articles" />
            </Col>
            <Col className='news-page-card-column' lg={8} md={8}>
              <Row className="mb-5">
                {newNodes.length > 0
                  ? newNodes.map((article, i) => {
                    return (
                      (i === 0)
                        ? <Col
                          key={i}
                          className="px-xs-0 px-md-3"
                          xs={12}
                        >
                          <Card
                            isGatsbyImage
                            cardClass="new-horizontal-card"
                            imageClass="new-horizontal-card-img"
                            cardBodyClass="news-page-horizontal-card-body"
                            btnClass="news-page-horizontal-card-btn"
                            title={article.node.title}
                            desc={`${article.node.description.substr(0, 100)}...`}
                            image={article.node.image.localFile.childImageSharp}
                            url={`/articles-single/${article.node.id}`}
                          // onClick={() => getArticleDetails({ id: article.node.id })}
                          />
                        </Col>
                        : <Col
                          className="p-xs-0 px-md-3"
                          key={i}
                          xs={12}
                          lg={6}
                        >
                          <Card
                            isGatsbyImage
                            cardClass="mt-5 new-vertical-card"
                            imageClass="new-vertical-card-img"
                            cardBodyClass="news-page-vertical-card-body"
                            btnClass="news-page-horizontal-card-btn"
                            title={article.node.title}
                            desc={`${article.node.description && article.node.description.substr(0, 100)}...`}
                            image={article.node.image.localFile.childImageSharp}
                            url={`/articles-single/${article.node.id}`}
                          // onClick={() => getArticleDetails({ id: article.node.id })}
                          />
                        </Col>
                    )
                  })
                  : null
                }
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    articles: state.articles.data,
    page: state.articles.current_page
  };
};

const mapDispatchToProps = {
  getArticles
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);

export const query = graphql`
query articlesQuery {
  allStrapiArticles {
    edges {
      node {
        id: strapiId
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            publicURL
          }
          url
        }
        title
        description
      }
    }
  }
}`
